//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';

.scroll-window {
  width: 100%;
  margin: auto;
  // border: 7px solid #1d505d;
  border-radius: 5px;
  box-shadow: 0px 5px 8px 2px #bcc6ff;

  img {
    cursor: n-resize;
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: 210px;
    transition: 8s all ease;

    &:hover {
      object-position: bottom;
    }
  }
}
